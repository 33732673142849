import { localStorageService } from '../services/localStorageService';
import { ORGANIZATION_ACCOUNTS_DATA } from '../constants/storageKeys';
import { OrganizationAccountsCacheData, UpdateAccountInformationWithRefreshTokenData } from '../types/sta';
import ApiHelper from '../api';

export const AuthViewModel = () => {
  const handleRefreshAccessToken = async (refreshToken: string, userId: string) => {
    const refreshTokenResponse = await ApiHelper.refreshAccessTokenRequest(refreshToken);

    if (!refreshTokenResponse || refreshTokenResponse.error) {
      updateAccountInformation(userId, {
        accessToken: null,
        refreshToken: null,
        accessTokenExpiresAt: null,
      });
    }

    if (refreshTokenResponse.data) {
      updateAccountInformation(userId, {
        accessToken: refreshTokenResponse.data.access_token,
        refreshToken: refreshTokenResponse.data.refresh_token,
        accessTokenExpiresAt: refreshTokenResponse.data.expires_at,
      });
    }
  };

  const updateAccountInformation = (userId: string, payload: UpdateAccountInformationWithRefreshTokenData) => {
    let savedCacheData: OrganizationAccountsCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(
      ORGANIZATION_ACCOUNTS_DATA,
    ) ?? {
      savedOrganizations: [],
      selectedAccountUserId: '',
    };

    const existingAccountIndex = savedCacheData.savedOrganizations.findIndex((org) => org.user.id === userId);

    if (existingAccountIndex !== -1) {
      let [existingOrgToModify] = savedCacheData.savedOrganizations.splice(existingAccountIndex, 1);

      savedCacheData.savedOrganizations.unshift({
        ...existingOrgToModify,
        accessToken: payload.accessToken,
        refreshToken: payload.refreshToken,
        accessTokenExpiresAt: payload.accessTokenExpiresAt,
      });
    }

    window.localStorage.setItem(ORGANIZATION_ACCOUNTS_DATA, JSON.stringify(savedCacheData));
  };
  return { handleRefreshAccessToken };
};
