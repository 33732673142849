import React from 'react';
import { Redirect } from 'react-router-dom';
import HypercareLogo from 'src/assets/static/horizontal-logo-white.svg';
import { connect } from 'react-redux';
import { RootState } from 'src/redux/store';
import 'src/assets/styles/LoginStyles.scss';
import HypercareLoginCoordinator from 'src/pages/LoginPage/LoginCoordinator';
import { IsFeatureFlagEnabled } from '../../utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../utils/FeatureFlags';
import { HypercareLoginPageCoordinator } from '../NewLoginPage/LoginPageCoordinator';
import { OrganizationAccountsCacheData, SavedOrganization } from '../../types/sta';

interface Props {
  isAuthorized: boolean;
  savedAccountsData: SavedOrganization[];
  setSavedAccountsData: React.Dispatch<React.SetStateAction<SavedOrganization[]>>;
}

const LoginLayout = ({ isAuthorized, savedAccountsData, setSavedAccountsData }: Props) => {
  const staFlag = IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);
  if (isAuthorized) {
    return (
      <Redirect
        to={{
          pathname: '/home',
          state: { params: '/home' },
        }}
      />
    );
  }
  return (
    <>
      {!staFlag ? (
        <HypercareLoginPageCoordinator
          savedAccountsData={savedAccountsData}
          setSavedAccountsData={setSavedAccountsData}
        />
      ) : (
        <div className="login__wrapper">
          <div className="login__wrapper__central">
            <div
              className="hypercare_logo"
              onClick={() => alert(`Hypercare version is: ${process.env.REACT_APP_VERSION}`)}
            >
              <img src={HypercareLogo} alt="hypercare white logo" />
            </div>
            <HypercareLoginCoordinator />
          </div>
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isAuthorized: state.flagReducer.isAuthorized,
  };
};

const ConnectedLoginLayout = connect(mapStateToProps)(LoginLayout);

export default ConnectedLoginLayout;
