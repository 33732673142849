import React, { useState } from 'react';
import {
  HCCustomSpan,
  HCHeadingFour,
  HCHeadingThree,
  HCLabelOne,
  HCTextContext,
  PrimaryButton,
  SecondaryButton,
} from 'src/components/shared/HypercareComponents';
import { Divider, Grid, IconButton } from '@material-ui/core';
import {
  AuthRegionMap,
  CANCEL,
  CHANGE_REGION_CONFIRMATION_DIALOG_DESCRIPTION_ONE,
  CHANGE_REGION_CONFIRMATION_DIALOG_DESCRIPTION_TWO,
  CHANGE_REGION_CONFIRMATION_DIALOG_TITLE,
  CHANGE_REGION_MODAL_DESCRIPTION_TEXT,
  CHANGE_REGION_MODAL_SWITCH_BUTTON,
  CHANGE_REGION_MODAL_TITLE_TEXT,
} from 'src/constants/strings';
import * as ReactModal from 'react-modal';
import styled from 'styled-components';
import { AuthRegion, IAuthRegionData } from 'src/types';
import CloseIcon from 'src/assets/svgs/CloseIcon';

import AnalyticsManager, { EVENTS } from 'src/analytics/AnalyticsManager';
import { ChangeRegionViewModel } from '../viewModels/ChangeRegionViewModel';
import { toast } from 'react-toastify';

import theme from 'src/assets/styles/theme';
import { SavedOrganization } from '../../types/sta';
import { CustomModal } from '../../microfrontend/modals/CustomModal';
import { ORGANIZATION_ACCOUNTS_DATA } from '../../constants/storageKeys';

const ChangeRegionModalDivider = styled(Divider)`
  border: none;
  margin-top: 16px !important;
  margin-left: -24px !important;
  margin-right: -24px !important;
`;

const ChangeRegionButtonContainer = styled.div`
  display: flex;
  margin-top: 16px;
  justify-content: flex-end;
  gap: 8px;
`;

const ChangeRegionModalBodyTextContainer = styled.div`
  margin: 12px 0 24px 0;
`;

const ChangeRegionModalAuthRegionListItems = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
`;

const RegionSelectorItem = styled.li<{ $isSelected: boolean }>`
  background: ${(props) => (props.$isSelected ? '#F6F6F9' : 'transparent')};
  box-shadow: ${(props) => (props.$isSelected ? '0px 1px 4px rgba(0, 0, 0, 0.1)' : 'unset')};
`;

interface IChangeRegionModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setSavedAccountsData?: React.Dispatch<React.SetStateAction<SavedOrganization[]>>;
}

const ChangeRegionModal = ({ isOpen, setIsOpen, setSavedAccountsData }: IChangeRegionModal) => {
  const { currentRegion, AuthRegionData, submitRegionChange } = ChangeRegionViewModel();

  const [selectedRegion, setSelectedRegion] = useState<AuthRegion>(currentRegion);
  const [confirmationModal, setShowConfirmationModal] = useState(false);

  const closeChangeRegionModal = () => {
    setIsOpen(false);
  };

  const handleRegionChange = (option: IAuthRegionData) => {
    setSelectedRegion(option.region);
  };

  const handleSubmitRegionChange = () => {
    if (currentRegion !== selectedRegion) {
      setShowConfirmationModal(true);
    } else {
      setIsOpen(false);
    }
  };

  const handleConfirmationChange = () => {
    const result = submitRegionChange(selectedRegion);
    if (result.success) {
      closeChangeRegionModal();
      setSavedAccountsData && setSavedAccountsData([]);
      localStorage.removeItem(ORGANIZATION_ACCOUNTS_DATA);
      AnalyticsManager.applyAnalytics({
        eventName: EVENTS.switchRegionPressed,
        params: {
          region_name: selectedRegion,
        },
      });
    } else {
      toast.error(result.error, {
        className: 'Toast-Container',
        autoClose: 10000,
      });
    }
  };

  return (
    <>
      <ReactModal
        overlayClassName="modal__overlay"
        className="modal__changeRegion"
        isOpen={isOpen}
        ariaHideApp={false}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        onRequestClose={closeChangeRegionModal}
      >
        <IconButton
          aria-label="close"
          onClick={closeChangeRegionModal}
          style={{ position: 'absolute', top: 16, right: 16 }}
        >
          <CloseIcon color="#222222" width="15" height="15" />
        </IconButton>

        <HCHeadingThree id="alert-dialog-title">{CHANGE_REGION_MODAL_TITLE_TEXT}</HCHeadingThree>

        <ChangeRegionModalBodyTextContainer>
          <HCLabelOne>{CHANGE_REGION_MODAL_DESCRIPTION_TEXT}</HCLabelOne>
        </ChangeRegionModalBodyTextContainer>

        <ChangeRegionModalAuthRegionListItems>
          {AuthRegionData.map((opt, index) => (
            <RegionSelectorItem
              key={`${index}-${opt.regionName}`}
              $isSelected={selectedRegion === opt.region}
              onClick={() => handleRegionChange(opt)}
            >
              <img width="24px" height="24px" src={opt?.flagIcon} alt={opt?.regionName} />
              <HCTextContext>{opt.regionName}</HCTextContext>
            </RegionSelectorItem>
          ))}
        </ChangeRegionModalAuthRegionListItems>

        <ChangeRegionModalDivider />

        <ChangeRegionButtonContainer>
          <SecondaryButton onClick={closeChangeRegionModal}>
            <HCHeadingFour color={theme.black}>{CANCEL}</HCHeadingFour>
          </SecondaryButton>
          <PrimaryButton background={theme.errorRed} disabled={!selectedRegion} onClick={handleSubmitRegionChange}>
            <HCHeadingFour color={theme.white}>
              {CHANGE_REGION_MODAL_SWITCH_BUTTON(selectedRegion, AuthRegionMap)}
            </HCHeadingFour>
          </PrimaryButton>
        </ChangeRegionButtonContainer>
      </ReactModal>

      <CustomModal
        width="sm"
        titleColor={theme.watermelon}
        title={CHANGE_REGION_CONFIRMATION_DIALOG_TITLE}
        titleFontSize="21px"
        isAlertModalVisible={confirmationModal}
        disableBackDropClick={true}
        showCloseButton={true}
        closeAlertModal={() => setShowConfirmationModal(false)}
        alertModalButtons={[
          {
            type: 'secondary',
            buttonLabel: 'Cancel',
            onClickHandler: () => setShowConfirmationModal(false),
            id: 'logout-btn',
            color: 'black',
          },
          {
            type: 'primary',
            buttonLabel: 'Continue',
            onClickHandler: () => handleConfirmationChange(),
            id: 'continue-session-btn',
          },
        ]}
        modalContent={
          <Grid container justify={'space-between'} spacing={1}>
            <Grid item>
              <HCCustomSpan>{CHANGE_REGION_CONFIRMATION_DIALOG_DESCRIPTION_ONE}</HCCustomSpan>
            </Grid>
            <Grid item>
              <HCCustomSpan>{CHANGE_REGION_CONFIRMATION_DIALOG_DESCRIPTION_TWO}</HCCustomSpan>
            </Grid>
          </Grid>
        }
      />
    </>
  );
};

export default ChangeRegionModal;
