import React from 'react';
import { Dialog, IconButton } from '@material-ui/core';
import { DialogTitleTypography, Transition } from '../../../components/shared/HypercareComponents';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseMark from '../../../assets/svgs/CloseMark';
import {
  CANCEL,
  LOGIN_PAGE_REMOVE_ALL_DEVICE_MODAL_DESCRIPTION,
  LOGIN_PAGE_REMOVE_ALL_DEVICE_MODAL_TITLE,
} from '../../../constants/strings';
import { REMOVE } from '../../../constants/virtualPagerStrings';
import { CustomModal } from '../../../microfrontend/modals/CustomModal';

interface ILogoutAllAccountsModalProps {
  isOpen: boolean;
  setIsOpen: (value: React.SetStateAction<boolean>) => void;
  handleOnClick: () => void;
  title?: string;
  subTitle?: string;
}

export const LogoutAllAccountsModal = ({ isOpen, setIsOpen, handleOnClick }: ILogoutAllAccountsModalProps) => {
  return (
    <CustomModal
      width="sm"
      title={LOGIN_PAGE_REMOVE_ALL_DEVICE_MODAL_TITLE}
      titleFontSize="21px"
      subtitle={LOGIN_PAGE_REMOVE_ALL_DEVICE_MODAL_DESCRIPTION}
      closeAlertModal={() => setIsOpen(false)}
      isAlertModalVisible={isOpen}
      alertModalButtons={[
        {
          type: 'secondary',
          buttonLabel: CANCEL,
          onClickHandler: () => setIsOpen(false),
          id: 'cancel-btn',
        },
        {
          type: 'primary',
          buttonLabel: REMOVE,
          onClickHandler: () => handleOnClick(),
          id: 'remove-btn',
        },
      ]}
    />
  );
};
