import React from 'react';
import styled from 'styled-components';
import { Box } from '@material-ui/core';
import {
  HCBodyOne,
  HCCustomSpan,
  HCHeadingOne,
  HCHeadingThree,
  HCHeadingTwo,
  HCLabelOne,
  HCLabelSix,
  PrimaryButton,
} from '../../../components/shared/HypercareComponents';
import HypercareWhiteLogo from '../../../assets/static/horizontal-logo-red.svg';
import theme from '../../../assets/styles/theme';
import HypercareAdminRedBackground from '../../../assets/static/HypercareAdminRedBackground.png';
import { StyledLink } from '../../../components/shared/SharedStyles';
import { CredentialsIcon } from '../../../assets/svgs/CredentialsIcon';
import { CalendarIconV2 } from '../../../assets/svgs/CalendarIconV2';
import { ChartIcon } from '../../../assets/svgs/ChartIcon';
import { DashboardIcon } from '../../../assets/svgs/DashboardIcon';
import {
  HELP_CENTER_LINK,
  HELP_CENTER_TEXT,
  INTRO_PAGE_DASHBOARD_TITLE,
  INTRO_PAGE_LEFT_SIDE_TITLE,
  INTRO_PAGE_LOGIN_BUTTON_TEXT,
  INTRO_PAGE_MANAGE_USER_INFO_SUBTITLE,
  INTRO_PAGE_MANAGE_USER_INFO_TITLE,
  INTRO_PAGE_METRIC_SUBTITLE,
  INTRO_PAGE_METRIC_TITLE,
  INTRO_PAGE_RIGHT_SIDE_TITLE,
  INTRO_PAGE_SCHEDULE_SUBTITLE,
  INTRO_PAGE_SCHEDULE_TITLE,
  PRIVACY_POLICY_LINK,
  PRIVACY_POLICY_TEXT,
  TERMS_AGREEMENT_TEXT,
  TERMS_OF_USE_LINK,
  TERMS_OF_USE_TEXT,
} from '../../../constants/strings';
import { INTRO_DONE } from '../../../constants/storageKeys';
import { getClientWebAppUrl, getRedirectUriForAdmin } from '../../../utils/sta/util';

const AuthIntroPageContainer = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;

  .left-section {
    flex: 1;
    color: #222;
    background-image: url(${HypercareAdminRedBackground});
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 64px;
    box-sizing: border-box;
    font-family: 'OpenSans', sans-serif;
    font-size: 40px;
    line-height: 1.6;

    @media (max-width: 960px) {
      max-width: 40%;
      font-size: 32px;
      padding: 24px;
    }

    @media (max-width: 600px) {
      display: none;
    }
  }

  .right-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 48px;
    box-sizing: border-box;

    @media (max-width: 960px) {
      padding: 24px;
    }

    @media (max-width: 600px) {
      padding: 24px;
    }
  }

  .help-center {
    text-decoration: none;
    text-align: right;
    margin-bottom: 16px;
  }
`;

const LogoWrapper = styled.div`
  max-width: 264px;

  img {
    width: 100%;
  }
`;

const StyledStepInfoIcons = styled(Box)`
  background: #f6f6f9;
  border-radius: 5px;
  display: flex;
  width: 48px;
  height: 48px;
  padding: 8px;
  justify-content: center;
  align-items: center;
`;

interface IStepInfo {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
}

const StepInfo: React.FC<IStepInfo> = ({ icon, title, subtitle }) => (
  <Box display="flex" mb={3}>
    <StyledStepInfoIcons>{icon}</StyledStepInfoIcons>
    <Box ml={3}>
      <HCBodyOne>{title}</HCBodyOne>
      <HCLabelOne margin={'4px 0 0 0'} color={theme.mainFontColor}>
        {subtitle}
      </HCLabelOne>
    </Box>
  </Box>
);

export const IntroductionPage: React.FC = () => {
  const handleLoginButtonClick = () => {
    const encodedUriString = encodeURIComponent(`redirectUri=${getRedirectUriForAdmin()}&step=6`);

    window.open(`${getClientWebAppUrl()}?${encodedUriString}`, '_self');
  };
  return (
    <AuthIntroPageContainer>
      <Box className="left-section">
        <LogoWrapper>
          <img alt="logo" src={HypercareWhiteLogo} />
        </LogoWrapper>
        <HCHeadingOne color={theme.white}>{INTRO_PAGE_LEFT_SIDE_TITLE}</HCHeadingOne>
      </Box>

      <Box className="right-section">
        <a href={HELP_CENTER_LINK} target="_blank" rel="noopener noreferrer" className="help-center">
          <HCHeadingThree color={theme.mainFontColor}>{HELP_CENTER_TEXT}</HCHeadingThree>
        </a>

        <Box flex={1} display="flex" justifyContent="center" flexDirection="column">
          <Box marginBottom={5}>
            <HCHeadingTwo color={theme.black}>{INTRO_PAGE_RIGHT_SIDE_TITLE}</HCHeadingTwo>
          </Box>

          <StepInfo
            icon={<CredentialsIcon />}
            title={INTRO_PAGE_MANAGE_USER_INFO_TITLE}
            subtitle={INTRO_PAGE_MANAGE_USER_INFO_SUBTITLE}
          />
          <StepInfo
            icon={<CalendarIconV2 />}
            title={INTRO_PAGE_SCHEDULE_TITLE}
            subtitle={INTRO_PAGE_SCHEDULE_SUBTITLE}
          />
          <StepInfo icon={<ChartIcon />} title={INTRO_PAGE_METRIC_TITLE} subtitle={INTRO_PAGE_METRIC_SUBTITLE} />
          <StepInfo icon={<DashboardIcon />} title={INTRO_PAGE_DASHBOARD_TITLE} subtitle={INTRO_PAGE_DASHBOARD_TITLE} />

          <Box mt={3}>
            <PrimaryButton background={'#FF3E55'} border="none" onClick={handleLoginButtonClick}>
              <HCLabelSix color={theme.white}>{INTRO_PAGE_LOGIN_BUTTON_TEXT}</HCLabelSix>
            </PrimaryButton>
          </Box>

          <HCCustomSpan color={theme.mainFontColor} margin="16px 0 0">
            {TERMS_AGREEMENT_TEXT}{' '}
            <StyledLink href={TERMS_OF_USE_LINK} target="_blank" rel="noopener noreferrer">
              {TERMS_OF_USE_TEXT}{' '}
            </StyledLink>{' '}
            and{' '}
            <StyledLink href={PRIVACY_POLICY_LINK} target="_blank" rel="noopener noreferrer">
              {PRIVACY_POLICY_TEXT}{' '}
            </StyledLink>
          </HCCustomSpan>
        </Box>
      </Box>
    </AuthIntroPageContainer>
  );
};
