import React, { useContext, useEffect, useState } from 'react';

import HypercareAuthRegionModalContext from '../../contexts/HypercareAuthRegionModalContext';
import ChangeRegionModal from 'src/components/modals/ChangeRegionModal';
import { AccountSelectionView } from './AccountSelection/AccountSelectionView';
import { OrganizationAccountsCacheData, SavedOrganization } from '../../types/sta';
import { localStorageService } from '../../services/localStorageService';
import { SAVED_ACCOUNTS_COOKIE, ORGANIZATION_ACCOUNTS_DATA } from '../../constants/storageKeys';
import { AuthViewModel } from '../../auth/AuthViewModel';
import {
  getClientWebAppUrl,
  getRedirectUriForAdmin,
  isAccessTokenValid,
  isRefreshTokenValid,
} from '../../utils/sta/util';
import { LoginPageHypercareLogoContainer, PageContainer } from './styled/login.styled';
import { AuthContext } from '../../auth';
import { HCLogoRed } from '../../assets/svgs/HCLogoRed';
import { StaLogin } from '../../types';
import { LAST_ACTIVE_TIME } from '../../constants/sessionTimeoutTypes';

interface ILoginPageCoordinatorProps {
  staLogin: StaLogin;
  savedAccountsData: SavedOrganization[];
  setSavedAccountsData: React.Dispatch<React.SetStateAction<SavedOrganization[]>>;
}

const LoginPageCoordinator = ({ staLogin, savedAccountsData, setSavedAccountsData }: ILoginPageCoordinatorProps) => {
  const { changeRegionModalOpenStatus, setChangeRegionModalOpenStatus } = useContext(HypercareAuthRegionModalContext);

  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

  const { handleRefreshAccessToken } = AuthViewModel();

  useEffect(() => {
    if (currentCacheData?.savedOrganizations?.length && currentCacheData?.selectedAccountUserId) {
      const selectedAccount = currentCacheData.savedOrganizations.find(
        (acc) => acc.user.id === currentCacheData.selectedAccountUserId,
      );

      if (selectedAccount) {
        localStorage.removeItem(LAST_ACTIVE_TIME);
        staLogin(selectedAccount);
      }
    }
  }, [currentCacheData, staLogin]);

  useEffect(() => {
    const refreshTokenFlow = async () => {
      for (const acc of currentCacheData?.savedOrganizations || []) {
        if (!isAccessTokenValid(acc) && isRefreshTokenValid(acc)) {
          await handleRefreshAccessToken(acc.refreshToken || '', acc.user.id);
        }
      }
    };

    refreshTokenFlow();
  }, [currentCacheData, handleRefreshAccessToken]);

  const handleNext = () => {
    const encodedUriString = encodeURIComponent(`redirectUri=${getRedirectUriForAdmin()}&step=6`);

    window.open(`${getClientWebAppUrl()}?${encodedUriString}`, '_self');
  };

  const renderCurrentStep = () => (
    <AccountSelectionView
      handleNextStep={handleNext}
      savedAccountsData={savedAccountsData}
      setSavedAccountsData={setSavedAccountsData}
      staLogin={staLogin}
    />
  );

  return (
    <PageContainer>
      <LoginPageHypercareLogoContainer>
        <div>
          <HCLogoRed />
        </div>
      </LoginPageHypercareLogoContainer>
      {renderCurrentStep()}
      {changeRegionModalOpenStatus && (
        <ChangeRegionModal
          isOpen={changeRegionModalOpenStatus}
          setIsOpen={setChangeRegionModalOpenStatus}
          setSavedAccountsData={setSavedAccountsData}
        />
      )}

      {changeRegionModalOpenStatus && (
        <ChangeRegionModal isOpen={changeRegionModalOpenStatus} setIsOpen={setChangeRegionModalOpenStatus} />
      )}
    </PageContainer>
  );
};

export const HypercareLoginPageCoordinator = ({
  savedAccountsData,
  setSavedAccountsData,
}: {
  savedAccountsData: SavedOrganization[];
  setSavedAccountsData: React.Dispatch<React.SetStateAction<SavedOrganization[]>>;
}) => {
  return (
    <AuthContext.Consumer>
      {({ staLogin }) => (
        <LoginPageCoordinator
          savedAccountsData={savedAccountsData}
          setSavedAccountsData={setSavedAccountsData}
          staLogin={staLogin}
        />
      )}
    </AuthContext.Consumer>
  );
};
