import React from 'react';
import { connect } from 'react-redux';
import Logo from 'src/assets/static/hcLogo.png';
import { RouteChildrenProps, withRouter } from 'react-router-dom';
import OrganizationSwitcher from 'src/components/headers/global-header/OrganizationSwitcher';
import UserOptions from 'src/components/headers/global-header/UserOptions';
import { RootState } from 'src/redux/store';
import { UserOrganizationSwitcherPayload } from 'src/types';
import 'src/assets/styles/HeaderStyles.scss';
import { getSelfInfo } from 'src/utils/getLocalAuth';
import { IsFeatureFlagEnabled } from 'src/utils/FeatureFlagManager';
import { NEW, VIRTUAL_PAGERS } from 'src/constants/virtualPagerStrings';
import { RedTag } from '../shared/HypercareComponents';
import { AppRoutes } from '../../router/AppRoutes';
import AccountSwitchDropdownContainer from '../../pages/NewLoginPage/AccountSwitch/AccountSwitchDropdownContainer';

interface GlobalHeaderLeftSectionProps {
  history: any;
  currentOrganization: UserOrganizationSwitcherPayload;
}

const GlobalHeaderLeftSection = ({ history, currentOrganization }: GlobalHeaderLeftSectionProps) => {
  return (
    <>
      {/*<div className="globalHeader__organization">*/}
      {/*  <button className="globalHeader__organization__logoWrapper" onClick={() => history.push(AppRoutes.Home)}>*/}
      {/*    <img alt="logo" src={currentOrganization.image || Logo} />*/}
      {/*  </button>*/}
      {/*  <OrganizationSwitcher />*/}
      {/*</div>*/}
      <AccountSwitchDropdownContainer />
    </>
  );
};

interface GlobalHeaderRightSectionProps {
  history: any;
  currentOrganization: UserOrganizationSwitcherPayload;
}

const GlobalHeaderRightSection = ({ history, currentOrganization }: GlobalHeaderRightSectionProps) => {
  const { id } = getSelfInfo();
  const VirtualPagerFlag = IsFeatureFlagEnabled('virtualPagerNavigationTab');

  return (
    <div className="globalHeader__userSection">
      <div className="globalHeader__userNavbtn" onClick={() => history.push(AppRoutes.Home)}>
        <span>Users</span>
      </div>
      <div className="globalHeader__userNavbtn" onClick={() => history.push(AppRoutes.Dashboard)}>
        <span>Analytics</span>
      </div>
      {VirtualPagerFlag && (
        <div
          className="globalHeader__userNavbtn"
          onClick={() => {
            history.push(AppRoutes.VirtualPagers);
          }}
        >
          <span>{VIRTUAL_PAGERS}</span>
          <RedTag>{NEW}</RedTag>
        </div>
      )}
      <div className="globalHeader__userNavbtn" onClick={() => history.push(AppRoutes.Scheduling)}>
        <span>Schedules</span>
      </div>
      {currentOrganization.type === 'department' &&
        (process.env.REACT_APP_ENV !== 'production' ||
          [
            '15c412c0-1b04-11e7-b9b9-8f522ec4d570',
            'b71aa8f0-1b54-11e7-80c2-a169741db4ac',
            '491faae5-1b5b-4e81-8a57-328ccd95ec48',
          ].includes(id) ||
          currentOrganization.organization_id === 23) && (
          <div className="globalHeader__userNavbtn" onClick={() => history.push(AppRoutes.Escalation)}>
            <span>Escalations</span>
          </div>
        )}
      <UserOptions />
    </div>
  );
};

interface GlobalHeaderProps extends RouteChildrenProps {
  isAuthorized: boolean;
  currentOrganization: UserOrganizationSwitcherPayload;
  isWaiting: boolean;
}

const GlobalHeader = ({ history, isAuthorized, currentOrganization }: GlobalHeaderProps) => {
  if (isAuthorized) {
    return (
      <div className="globalHeader" id="globalHeader">
        <GlobalHeaderLeftSection history={history} currentOrganization={currentOrganization} />
        <GlobalHeaderRightSection history={history} currentOrganization={currentOrganization} />
      </div>
    );
  }
  return null;
};

const mapStateToProps = (state: RootState) => {
  return {
    isWaiting: state.flagReducer.isWaitingSwitchOrganization,
    isAuthorized: state.flagReducer.isAuthorized,
    currentOrganization: state.organizationReducer,
  };
};

export default connect(mapStateToProps)(withRouter(GlobalHeader));
