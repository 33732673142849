import React from 'react';
import { typedUseSelector } from '../../../redux/store';
import { List, ListItem } from '@material-ui/core';
import { DepartmentScope, OrganizationScope, SiteScope, UserOrganizationSwitcherPayload } from '../../../types';
import styled from 'styled-components';
import { HCBodyTwo } from '../../shared/HypercareComponents';
import theme from '../../../assets/styles/theme';
import trySwitchOrganization from '../../../utils/trySwitchOrganization';
import { DEPARTMENT, SITE } from '../../../constants/organizationTypes';
import { OrganizationReducerState } from '../../../redux/reducers/organizationReducer';
import CheckIcon from '../../../assets/svgs/CheckIcon';
import { organizationDropDownAction } from '../../../redux/actions/flagAction';
import { useDispatch } from 'react-redux';

interface IOrganizationListProps {
  currentOrganization: UserOrganizationSwitcherPayload;
}

const StyledSite = styled.div`
  border-bottom: 1px solid #d8d8d8;
  height: 46px;
  justify-content: flex-start;
  align-items: center;

  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const OrganizationList = ({ currentOrganization }: IOrganizationListProps) => {
  const userOrganization = typedUseSelector((store) => store.userOrganizationReducer.organizations);
  const dispatch = useDispatch();
  console.log(userOrganization, 'userOrganization');

  const renderSiteList = (userOrganization: OrganizationScope[]) => {
    if (!userOrganization) {
      return;
    }
    const filteredSite = userOrganization[0].sites
      .filter((site) => site.isAdmin)
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
    console.log(filteredSite);

    const handleSiteClick = (site, event) => {
      event.stopPropagation(); // Prevent event bubbling
      console.log(site, 'site');
      dispatch(organizationDropDownAction(false));

      trySwitchOrganization({
        name: site.name,
        type: SITE,
        site_id: site.id,
        image: site.image,
        organization_id: currentOrganization.organization_id,
        department_id: null,
        organization_name: currentOrganization.name,
      });
    };

    return (
      <div>
        {filteredSite.map((site, i) => (
          <ListItem
            style={{
              paddingLeft: '72px',
              display: 'list-item',
              paddingTop: 0,
              paddingRight: '0',
            }}
            key={`scope-${userOrganization[0].id}-${site.id}-${i}`}
          >
            <StyledSite
              style={{
                borderBottom: '1px solid #D8D8D8',
                display: 'flex',
                height: '46px',
                justifyContent: 'flex-start',
                alignItems: 'center',
              }}
              onClick={(e) => handleSiteClick(site, e)}
            >
              {currentOrganization.site_id === site.id &&
              currentOrganization.type === SITE &&
              currentOrganization.organization_id === userOrganization[0].id ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '100%',
                    width: '100%',
                  }}
                >
                  <HCBodyTwo color={theme.black}>
                    <strong>{site.name}</strong>
                  </HCBodyTwo>

                  <CheckIcon />
                </div>
              ) : (
                <HCBodyTwo color={theme.black}>{site.name}</HCBodyTwo>
              )}
            </StyledSite>
            <DepartmentList
              currentOrganization={currentOrganization}
              currentSite={site}
              departments={site.departments}
            />
          </ListItem>
        ))}
      </div>
    );
  };

  return <List style={{ padding: 0, listStyleType: 'disc' }}>{renderSiteList(userOrganization)}</List>;
};

export const DepartmentList = ({
  currentOrganization,
  currentSite,
  departments,
}: {
  departments: DepartmentScope[];
  currentOrganization: OrganizationReducerState;
  currentSite: SiteScope;
}) => {
  const dispatch = useDispatch();
  const filteredDepatment = departments
    .filter((dept) => dept.isAdmin)
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  console.log(filteredDepatment);

  const handleDeptClick = (department, event) => {
    event.stopPropagation(); // Prevent event bubbling
    dispatch(organizationDropDownAction(false));

    trySwitchOrganization({
      name: department.name,
      type: DEPARTMENT,
      department_id: department.id,
      image: department.image,
      site_id: currentSite.id,
      organization_id: currentOrganization.organization_id,
      organization_name: currentOrganization.name,
    });
  };

  return (
    <>
      {filteredDepatment.map((dept, i) => (
        <ListItem
          style={{
            display: 'list-item',
            paddingLeft: '32px',
            padding: '12px 8px',
            listStyleType: 'disc',
          }}
          button
          onClick={(e) => handleDeptClick(dept, e)}
          key={`${dept.id}-${i}`}
        >
          {currentOrganization.department_id === dept.id &&
          currentOrganization.type === DEPARTMENT &&
          currentOrganization.site_id === currentSite.id ? (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <HCBodyTwo color={theme.black}>
                {' '}
                <strong>{dept.name}</strong>
              </HCBodyTwo>
              <CheckIcon />
            </div>
          ) : (
            <HCBodyTwo color={theme.black}>{dept.name}</HCBodyTwo>
          )}
        </ListItem>
      ))}
    </>
  );
};
