import React, { useEffect, useState } from 'react';
import LoginLayout from 'src/pages/LoginPage/LoginLayout';
import AnalyticsManager, { PAGE_VIEWS } from 'src/analytics/AnalyticsManager';
import { LogOutModalWrapper } from 'src/components/modals/LogoutPageTimeoutModal';
import { IDLE_TIMEOUT, SESSION_TIMEOUT_LOGINPAGE_MODAL } from 'src/constants/sessionTimeoutTypes';
import { ORGANIZATION_ACCOUNTS_DATA, SAVED_ACCOUNTS_COOKIE } from '../../constants/storageKeys';
import { IntroductionPage } from '../NewLoginPage/components/IntroductionPage';
import { localStorageService } from '../../services/localStorageService';
import { OrganizationAccountsCacheData, SavedOrganization } from '../../types/sta';
import { addDataToSavedAccounts, base64GzipDecode, eraseCookie, getCookieValue } from '../../utils/sta/util';

const LoginPage = () => {
  const [isOpen, setIsOpen] = useState<boolean>();
  const idleTimeoutValue = window.sessionStorage.getItem(IDLE_TIMEOUT);
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const [savedAccountsData, setSavedAccountsData] = useState<SavedOrganization[]>(currentCacheData?.savedOrganizations);

  useEffect(() => {
    AnalyticsManager.recordPageVisited(PAGE_VIEWS.loginView);
    const modalCloseValues = JSON.parse(window.sessionStorage.getItem(SESSION_TIMEOUT_LOGINPAGE_MODAL));
    const initialModalValue = modalCloseValues ? true : false;
    setIsOpen(initialModalValue);
  }, [isOpen]);

  useEffect(() => {
    const savedAccountsCookie = getCookieValue(SAVED_ACCOUNTS_COOKIE);
    if (savedAccountsCookie) {
      const base64GzipDecoded = base64GzipDecode(savedAccountsCookie);

      const savedAccountCookieValue: OrganizationAccountsCacheData = JSON.parse(base64GzipDecoded);

      if (currentCacheData?.savedOrganizations?.length) {
        const res = addDataToSavedAccounts(currentCacheData, savedAccountCookieValue);
        setSavedAccountsData(res.savedOrganizations);
      } else {
        localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, savedAccountCookieValue);
        setSavedAccountsData(savedAccountCookieValue.savedOrganizations);
      }

      eraseCookie(SAVED_ACCOUNTS_COOKIE);
    }
  }, []);

  if (Object.keys(currentCacheData ? currentCacheData : {}).length === 0) {
    return <IntroductionPage />;
  }

  return (
    <>
      {isOpen ? <LogOutModalWrapper isOpen={isOpen} setIsOpen={setIsOpen} timeoutValue={idleTimeoutValue} /> : null}
      <LoginLayout savedAccountsData={savedAccountsData} setSavedAccountsData={setSavedAccountsData} />
    </>
  );
};

export default LoginPage;
