import React, { PureComponent } from 'react';
import UserAvatar from 'src/components/shared/UserAvatar';
import UserOptionDropdown from 'src/components/popup-dropdowns/UserOptionDropdown';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AuthContext } from 'src/auth';
import { User } from 'src/types';
import { AppRoutes } from '../../../router/AppRoutes';
import { IsFeatureFlagEnabled } from '../../../utils/FeatureFlagManager';
import { FeatureFlagResult } from '../../../utils/FeatureFlags';
import { getParsedAuthInfo } from '../../../utils/localStorageHelper';
import { localStorageService } from '../../../services/localStorageService';
import { OrganizationAccountsCacheData } from '../../../types/sta';
import { ORGANIZATION_ACCOUNTS_DATA } from '../../../constants/storageKeys';
import { isAccessTokenValid } from '../../../utils/sta/util';
import { CustomModal } from '../../../microfrontend/modals/CustomModal';
import { LogoutViewModel } from '../../../pages/NewLoginPage/viewModels/LogoutViewModel';
import { toast } from 'react-toastify';

export const GlobalImageAvatar = (user) => {
  return (
    <div className="globalHeader__userAvatar">
      <UserAvatar user={user} profileSize="small" />
    </div>
  );
};

interface Props extends RouteComponentProps {
  fullName: string;
  user: User;
  logout: () => void;
  authInfo: any;
  isLoggedIn: Boolean;
}

const UserOptions = (props: Props) => {
  const staFlag = IsFeatureFlagEnabled(FeatureFlagResult.singleTenantAccountFlag);
  const [showStaLogoutModal, setShowStaLogoutModal] = React.useState(false);
  const { logoutOfAccount, removeAccount } = LogoutViewModel(props.logout);

  const handleOpenStaLogoutModal = () => {
    setShowStaLogoutModal(true);
  };

  const handleRemoveAccount = (existingOrg) => {
    const removeAccountResponse = removeAccount(existingOrg);

    if (removeAccountResponse?.success) {
      toast.info(
        `${existingOrg?.user.firstname} ${existingOrg?.user.lastname} (${existingOrg?.organization?.name}) has been removed from browser.`,
      );
      props.logout();
    } else {
      toast.error(`Unable to remove account, please refresh to try again`);
    }

    setShowStaLogoutModal(false);
  };

  const handleLogoutOfAccount = (existingOrg) => {
    const logoutResponse = logoutOfAccount(existingOrg);

    if (logoutResponse?.success) {
      toast.info(
        `Logged out of account ${existingOrg?.user.firstname} ${existingOrg?.user.lastname} (${existingOrg?.organization?.name})`,
      );
      props.logout();
    } else {
      toast.error(`Unable to logout of account, please refresh to try again`);
    }

    setShowStaLogoutModal(false);
  };

  const renderLogoutOfAccountModal = () => {
    const authInfo = getParsedAuthInfo();
    const cacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);

    const existingOrgIndex = cacheData?.savedOrganizations.findIndex((org) => org.user.id === authInfo?.user.id) || 0;

    if (cacheData && existingOrgIndex !== -1) {
      const existingOrg = cacheData.savedOrganizations[existingOrgIndex];

      const isUserSignedIn = isAccessTokenValid(existingOrg);

      const userFullName = `${existingOrg?.user.firstname} ${existingOrg?.user.lastname}`;
      const organizationName = existingOrg?.organization.name;

      const title = isUserSignedIn
        ? `Logout out of ${userFullName} (${organizationName})`
        : `Remove account ${userFullName} (${organizationName}) from browser?`;

      const subtitle = isUserSignedIn
        ? `We recommend also removing your account from device upon logging out if you’re using a shared device.`
        : 'You’ll need to enter your credentials again the next time you log into Hypercare on this device.';

      const alertModalButtons = [
        {
          type: 'primary',
          buttonLabel: 'Logout & Remove',
          onClickHandler: () => handleRemoveAccount(existingOrg),
          id: 'cancel-btn',
        },
        {
          type: 'primary',
          buttonLabel: 'Logout',
          onClickHandler: () => handleLogoutOfAccount(existingOrg),
          id: 'remove-btn',
        },
      ];

      return (
        <CustomModal
          width="sm"
          title={title}
          titleFontSize="21px"
          subtitle={subtitle}
          closeAlertModal={() => setShowStaLogoutModal(false)}
          isAlertModalVisible={showStaLogoutModal}
          alertModalButtons={alertModalButtons}
        />
      );
    }
  };

  const handleOptionSelection = (option, index) => {
    const { logout, authInfo } = props;
    switch (option.description) {
      case 'View profile':
        const { history } = props;
        history.push(`${AppRoutes.UserProfile}/${authInfo.user.id}`);
        break;
      case 'Sign out':
        !staFlag ? handleOpenStaLogoutModal() : logout();
        break;
      default:
        return;
    }
  };

  const { authInfo, isLoggedIn } = props;
  const fullName = `${authInfo.user.firstname} ${authInfo.user.lastname}`;
  const userOptions = [{ description: 'View profile' }, { description: 'Sign out' }];

  if (authInfo && isLoggedIn) {
    return (
      <>
        <UserOptionDropdown placeholder={fullName} options={userOptions} onSelect={handleOptionSelection} />
        {showStaLogoutModal && renderLogoutOfAccountModal()}
      </>
    );
  }
  return null;
};

const ConnectedUserOptions = withRouter(UserOptions);

export default (props) => (
  <AuthContext.Consumer>
    {({ logout, authInfo, isLoggedIn }) => (
      <ConnectedUserOptions {...props} isLoggedIn={isLoggedIn} logout={logout} authInfo={authInfo} />
    )}
  </AuthContext.Consumer>
);
