import { OrganizationAccountsCacheData, SavedOrganization } from '../../types/sta';
import { localStorageService } from '../../services/localStorageService';
import { ORGANIZATION_ACCOUNTS_DATA } from '../../constants/storageKeys';
import { ADMIN_APP_PROD_REDIRECT_URI, CLIENT_WEB_APP_PROD_URL } from '../../constants/strings';
import { inflate } from 'pako';
import { AuthRegion } from '../../types';

export const getCurrentLoggedInAccount = (cachedData: OrganizationAccountsCacheData | null) => {
  return cachedData?.savedOrganizations.find((account) => account.user.id === cachedData?.selectedAccountUserId);
};

export const checkForLoggedOutAccounts = (cachedData: OrganizationAccountsCacheData | null) => {
  if (!cachedData) {
    return false;
  }
  return cachedData?.savedOrganizations.some((account) => !account.accessToken);
};

export const isRefreshTokenValid = (account: SavedOrganization) => {
  return !!account.refreshToken;
};

export const isAccessTokenValid = (account: SavedOrganization) => {
  if (!account.accessToken) return false;

  if (!account.accessTokenExpiresAt) return false;

  let accessTokenExpiry = new Date(account.accessTokenExpiresAt);

  let now = new Date();

  return accessTokenExpiry > now;
};

export const getCookieValue = (name: string) =>
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || '';

export const eraseCookie = (name: string) => {
  document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=hypercare.com; Secure;';
};

export const getRedirectUriForAdmin = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return ADMIN_APP_PROD_REDIRECT_URI;
    case 'staging':
      return 'https://admin.app.release.hypercare.com/login';
    default:
      return ADMIN_APP_PROD_REDIRECT_URI;
  }
};

export const getClientWebAppUrl = () => {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return CLIENT_WEB_APP_PROD_URL;
    case 'staging':
      return 'https://app.release.hypercare.com';
    default:
      return CLIENT_WEB_APP_PROD_URL;
  }
};

export const formatSavedOrgPayloadForTransfer = (savedOrg: SavedOrganization, currentRegion: AuthRegion) => {
  const payload = {
    selectedAccount: savedOrg,
    clientId: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
    regionCode: currentRegion,
  };
  return payload;
};

export const decodeObjectToBase64 = <T extends object>(obj: T) => {
  if (obj) {
    return window.btoa(JSON.stringify(obj));
  }
};

export const addDataToSavedAccounts = (
  cachedData: OrganizationAccountsCacheData,
  incomingAccounts: OrganizationAccountsCacheData,
) => {
  const newData: SavedOrganization[] = [];

  for (let accs of incomingAccounts.savedOrganizations) {
    const isAccAlreadyInCache = cachedData.savedOrganizations.findIndex((acc) => acc.user.id === accs.user.id);

    if (isAccAlreadyInCache !== -1) {
      cachedData.savedOrganizations[isAccAlreadyInCache] = accs;
    } else {
      newData.push(accs);
    }
  }

  cachedData.savedOrganizations = cachedData.savedOrganizations.concat(newData);
  cachedData.selectedAccountUserId = incomingAccounts.selectedAccountUserId
    ? incomingAccounts.selectedAccountUserId
    : '';
  localStorageService.setItem(ORGANIZATION_ACCOUNTS_DATA, cachedData);
  return cachedData;
};

export const base64GzipDecode = (compressedBase64) => {
  // Convert the base64 string back to gzipped data (a Uint8Array)
  const binaryString = atob(compressedBase64);
  const charData = binaryString.split('').map((char) => char.charCodeAt(0));
  const gzippedData = new Uint8Array(charData);

  // Decompress the gzipped data
  const decompressedData = inflate(gzippedData);

  // Convert the decompressed data back to a string
  const textDecoder = new TextDecoder();
  const originalString = textDecoder.decode(decompressedData);

  return originalString;
};
