import React, { useState } from 'react';
import { Auth0ContextInterface } from '@auth0/auth0-react';
import { StaLogin } from '../../../types';
import { OrganizationAccountsCacheData, SavedOrganization } from '../../../types/sta';
import { localStorageService } from '../../../services/localStorageService';
import { AUTH_INFO, INTRO_DONE, ORGANIZATION_ACCOUNTS_DATA } from '../../../constants/storageKeys';
import { LogoutViewModel } from '../viewModels/LogoutViewModel';
import { getParsedAuthInfo, getParsedAuthRegion } from '../../../utils/localStorageHelper';
import {
  decodeObjectToBase64,
  formatSavedOrgPayloadForTransfer,
  getClientWebAppUrl,
  getRedirectUriForAdmin,
  isAccessTokenValid,
} from '../../../utils/sta/util';
import { toast } from 'react-toastify';
import store, { typedUseSelector } from '../../../redux/store';
import actions from '../../../redux/actions';
import { AccountSelectionEditModeTitle } from '../../../microfrontend/login/SavedAccountsTitle';
import { RowItemContainer, RowItemsRightSide } from '../../../microfrontend/styled/login.styled';
import {
  SavedAccountActionButtons,
  SavedAccountOrganizationRowItems,
} from '../../../microfrontend/login/SavedAccountOrganizationRowItems';
import { CloseButtonV2 } from '../../../microfrontend/svgs/CloseButtonV2';
import { CheckMarkWithoutBackground } from '../../../microfrontend/svgs/CheckMarkWithoutBackground';
import { AddButtonV2 } from '../../../microfrontend/svgs/AddButtonV2';
import { HCLabelOne } from '../../../components/shared/HypercareComponents';
import theme from '../../../assets/styles/theme';
import {
  LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT,
  LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT,
  LOGIN_PAGE_REMOVE_ALL_ACCOUNT,
} from '../../../constants/strings';
import { GearIcon } from '../../../microfrontend/svgs/GearIcon';
import { LogoutAllAccountsModal } from '../components/LogoutAllAccountsModal';
import { AccountLogoutModal } from '../components/AccountLogoutModal';
import { AuthContext } from '../../../auth';
import { OrganizationList } from '../../../components/popup-dropdowns/OrganizationDropdown/OrganizationList';
import { LAST_ACTIVE_TIME } from '../../../constants/sessionTimeoutTypes';
import trySwitchOrganization from '../../../utils/trySwitchOrganization';
import { ORGANIZATION, SITE } from '../../../constants/organizationTypes';
import { useDispatch } from 'react-redux';
import { organizationDropDownAction } from '../../../redux/actions/flagAction';

interface IDropdownProps {
  auth0props: Auth0ContextInterface;
  STALogin: StaLogin;
  logout: () => void;
  currentActiveAccount: SavedOrganization;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Dropdown = ({ STALogin, auth0props, logout, currentActiveAccount, setOpen }: IDropdownProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const currentCacheData = localStorageService.getItem<OrganizationAccountsCacheData>(ORGANIZATION_ACCOUNTS_DATA);
  const [currentSelectedAcc, setCurrentSelectedAcc] = useState<SavedOrganization | null>(null);
  const [showLogoutAccountModal, setShowLogoutAccountModal] = useState(false);
  const [showRemoveAccountModal, setShowRemoveAccountModal] = useState(false);

  const { logoutOfAccount, removeAccount } = LogoutViewModel(logout);
  const [showLogoutAllAccountsModal, setShowLogoutAllAccountsModal] = useState(false);
  const currentRegion = getParsedAuthRegion();
  const currentOrg = typedUseSelector((store) => store.organizationReducer);
  const dispatch = useDispatch();

  const handleOpenRemoveAccountModal = (organization: SavedOrganization) => {
    let isUserSignedIn = isAccessTokenValid(organization);
    setCurrentSelectedAcc(organization);

    isUserSignedIn ? setShowLogoutAccountModal(true) : setShowRemoveAccountModal(true);
  };

  const handleRemoveAccount = () => {
    const removeAccountResponse = removeAccount(currentSelectedAcc);

    if (removeAccountResponse?.success) {
      toast.info(
        `${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name}) has been removed from browser.`,
      );
    } else {
      toast.error(`Unable to remove account, please refresh to try again`);
    }

    setIsEditMode(false);
    setShowRemoveAccountModal(false);
    setShowLogoutAccountModal(false);
  };

  const handleLogoutOfAccount = () => {
    const logoutResponse = logoutOfAccount(currentSelectedAcc);

    if (logoutResponse?.success) {
      toast.info(
        `Logged out of account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name})`,
      );
    } else {
      toast.error(`Unable to logout of account, please refresh to try again`);
    }

    setIsEditMode(false);
    setShowLogoutAccountModal(false);
  };

  const handleRemoveAllAccountsOnDevice = () => {
    localStorageService.removeItem(ORGANIZATION_ACCOUNTS_DATA);
    localStorageService.removeItem(AUTH_INFO);
    window.location.assign(`/`);
  };

  const handleLoginToAccount = async (savedOrg: SavedOrganization) => {
    let isUserSignedIn = isAccessTokenValid(savedOrg);
    if (!isUserSignedIn) {
      const formatResponse = formatSavedOrgPayloadForTransfer(savedOrg, currentRegion);
      const base64EncodedResponse = decodeObjectToBase64(formatResponse);

      const encodedUriString = encodeURIComponent(
        `redirectUri=${getRedirectUriForAdmin()}&selectedAccount=${base64EncodedResponse}`,
      );

      window.open(`${getClientWebAppUrl()}?${encodedUriString}`, '_self');
    } else {
      if (
        currentCacheData.selectedAccountUserId === savedOrg.user.id &&
        currentOrg.organization_id === savedOrg.organization.id &&
        currentOrg.type === 'organization'
      ) {
        return;
      }
      dispatch(organizationDropDownAction(false));
      localStorage.removeItem(LAST_ACTIVE_TIME);
      await STALogin(savedOrg);
    }
  };

  const handleClickAddOrCreateAnotherAccount = () => {
    const encodedUriString = encodeURIComponent(`redirectUri=${getRedirectUriForAdmin()}&step=6`);

    window.open(`${getClientWebAppUrl()}?${encodedUriString}`, '_self');
  };

  return (
    <div>
      {isEditMode && (
        <AccountSelectionEditModeTitle
          onClick={() => setIsEditMode(false)}
          title={'Manage accounts'}
          padding={'24px 16px'}
        />
      )}{' '}
      <RowItemContainer>
        {currentCacheData?.savedOrganizations.map((savedOrg) => (
          <SavedAccountOrganizationRowItems
            key={savedOrg.user.id}
            user={savedOrg.user}
            organization={savedOrg.organization}
            isSignedIn={isAccessTokenValid(savedOrg)}
            isEditMode={isEditMode}
            handleRowClick={() => handleLoginToAccount(savedOrg)}
            rightSideContent={
              isEditMode ? (
                <RowItemsRightSide onClick={() => handleOpenRemoveAccountModal(savedOrg)}>
                  <CloseButtonV2 />
                </RowItemsRightSide>
              ) : (
                <RowItemsRightSide>
                  {currentActiveAccount.user.id === savedOrg.user.id &&
                    currentOrg.organization_id === savedOrg.organization.id && <CheckMarkWithoutBackground />}
                </RowItemsRightSide>
              )
            }
            showOrganizationContent={
              !isEditMode &&
              currentActiveAccount.user.id === savedOrg.user.id && <OrganizationList currentOrganization={currentOrg} />
            }
          />
        ))}
        <SavedAccountActionButtons
          onIconClick={() => handleClickAddOrCreateAnotherAccount()}
          icon={<AddButtonV2 />}
          description={
            <HCLabelOne color={theme.mainFontColor} lineHeight={'24px'}>
              {LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT}
            </HCLabelOne>
          }
        />
        {isEditMode ? (
          <SavedAccountActionButtons
            icon={<CloseButtonV2 />}
            onIconClick={() => setShowLogoutAllAccountsModal(true)}
            description={
              <HCLabelOne color={theme.mainFontColor} lineHeight={'24px'}>
                {LOGIN_PAGE_REMOVE_ALL_ACCOUNT}
              </HCLabelOne>
            }
          />
        ) : (
          <SavedAccountActionButtons
            icon={<GearIcon />}
            onIconClick={() => setIsEditMode(true)}
            description={
              <HCLabelOne color={theme.mainFontColor} lineHeight={'24px'}>
                {LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT}
              </HCLabelOne>
            }
          />
        )}
      </RowItemContainer>
      {showLogoutAllAccountsModal && (
        <LogoutAllAccountsModal
          isOpen={showLogoutAllAccountsModal}
          setIsOpen={setShowLogoutAllAccountsModal}
          handleOnClick={handleRemoveAllAccountsOnDevice}
        />
      )}
      {showLogoutAccountModal && (
        <AccountLogoutModal
          title={`Logout out of ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname || ''} (${
            currentSelectedAcc?.organization.name
          })`}
          subTitle={`We recommend also removing your account from device upon logging out if you’re using a shared device.`}
          isOpen={showLogoutAccountModal}
          setIsOpen={setShowLogoutAccountModal}
          modalButtons={[
            {
              type: 'primary',
              buttonLabel: 'Logout & Remove',
              onClickHandler: handleRemoveAccount,
              id: 'cancel-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Logout',
              onClickHandler: handleLogoutOfAccount,
              id: 'remove-btn',
            },
          ]}
        />
      )}
      {showRemoveAccountModal && (
        <AccountLogoutModal
          title={`Remove account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname || ''} (${
            currentSelectedAcc?.organization.name
          }) from browser?`}
          subTitle={`You’ll need to enter your credentials again the next time you log into Hypercare on this device.`}
          isOpen={showRemoveAccountModal}
          setIsOpen={setShowRemoveAccountModal}
          modalButtons={[
            {
              type: 'secondary',
              buttonLabel: 'Cancel',
              onClickHandler: () => setShowRemoveAccountModal(false),
              id: 'cancel-btn',
            },
            {
              type: 'primary',
              buttonLabel: 'Remove',
              onClickHandler: handleRemoveAccount,
              id: 'remove-btn',
            },
          ]}
        />
      )}
    </div>
  );
};

export const SwitchAccountsDropdownItems = ({
  currentActiveAccount,
  setOpen,
}: {
  currentActiveAccount: SavedOrganization;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <AuthContext.Consumer>
      {({ auth0props, staLogin, logout }) => (
        <Dropdown
          currentActiveAccount={currentActiveAccount}
          logout={logout}
          STALogin={staLogin}
          auth0props={auth0props}
          setOpen={setOpen}
        />
      )}
    </AuthContext.Consumer>
  );
};
