import React from 'react';
import { CustomModal } from '../../../microfrontend/modals/CustomModal';

export const AccountLogoutModal = ({ title, subTitle, isOpen, setIsOpen, modalButtons }) => {
  return (
    <CustomModal
      width="sm"
      title={title}
      titleFontSize="21px"
      subtitle={subTitle}
      closeAlertModal={() => setIsOpen(false)}
      isAlertModalVisible={isOpen}
      alertModalButtons={modalButtons}
    />
  );
};
