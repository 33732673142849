import styled from 'styled-components';
import theme from '../../../assets/styles/theme';

export const PageContainer = styled.div`
  width: 100vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
  overflow-x: hidden;
  background-color: #ffd8dd;
`;

export const LoginPageHypercareLogoContainer = styled.div`
  width: 250px !important;
  height: 48px !important;
  text-align: left !important;
`;

export const FilterItemContainer = styled.div`
  padding: 12px 0 12px 0;
  border-radius: 4px;
  display: flex;
  gap: 10px;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  :hover {
    background: #f6f6f9;
    cursor: pointer;
  }
`;

export const OrganizationNoIconWrapper = styled.div`
  width: 32px;
  height: 32px;
  background-color: ${theme.greyBorder};
  color: white;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PreferenceCellWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
`;

export const PreferencesCellIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FindUserOrganizationContainer = styled.div`
  max-width: 550px;
  width: 100%;
  flex-shrink: 0;
  border: 0.5px solid #d8d8d8;
  border-radius: 4px;
  z-index: 1;
  background-color: white;
`;

export const FlexColumn = styled.div`
  display: flex;
  max-width: 550px;
  width: 100%;
  flex-direction: column;
  gap: 24px;
`;

export const PaddedContainer = styled.div`
  padding: 0 24px 16px 24px;
`;

export const FilterItemContent = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const DescriptionColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
`;
