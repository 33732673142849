import React, { useContext, useState } from 'react';

import { toast } from 'react-toastify';
import { LogoutViewModel } from '../viewModels/LogoutViewModel';
import { LogoutAllAccountsModal } from '../components/LogoutAllAccountsModal';
import { AccountLogoutModal } from '../components/AccountLogoutModal';

import { SavedOrganization } from '../../../types/sta';
import HypercareAuthRegionModalContext from '../../../contexts/HypercareAuthRegionModalContext';
import { localStorageService } from '../../../services/localStorageService';
import {
  decodeObjectToBase64,
  formatSavedOrgPayloadForTransfer,
  getClientWebAppUrl,
  getRedirectUriForAdmin,
  isAccessTokenValid,
} from '../../../utils/sta/util';
import { AUTH_INFO, ORGANIZATION_ACCOUNTS_DATA } from '../../../constants/storageKeys';
import { getParsedAuthRegion } from '../../../utils/localStorageHelper';
import { LoginContainer, RowItemContainer, RowItemsRightSide } from '../../../microfrontend/styled/login.styled';
import { AccountSelectionEditModeTitle, AccountSelectionTitle } from '../../../microfrontend/login/SavedAccountsTitle';
import {
  SavedAccountActionButtons,
  SavedAccountOrganizationRowItems,
} from '../../../microfrontend/login/SavedAccountOrganizationRowItems';
import { CloseButtonV2 } from '../../../microfrontend/svgs/CloseButtonV2';
import { AddButtonV2 } from '../../../microfrontend/svgs/AddButtonV2';
import { HCLabelOne } from '../../../components/shared/HypercareComponents';
import theme from '../../../assets/styles/theme';
import { GearIcon } from '../../../microfrontend/svgs/GearIcon';
import {
  LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT,
  LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT,
  LOGIN_PAGE_REMOVE_ALL_ACCOUNT,
} from '../../../constants/strings';
import { StaLogin } from '../../../types';
import { LAST_ACTIVE_TIME } from '../../../constants/sessionTimeoutTypes';

interface IAccountSelectionViewProps {
  handleNextStep: () => void;
  staLogin: StaLogin;
  savedAccountsData: SavedOrganization[];
  setSavedAccountsData: React.Dispatch<React.SetStateAction<SavedOrganization[]>>;
}

export const AccountSelectionView = ({
  handleNextStep,
  staLogin,
  savedAccountsData,
  setSavedAccountsData,
}: IAccountSelectionViewProps) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [showLogoutAllAccountsModal, setShowLogoutAllAccountsModal] = useState(false);
  const [showLogoutAccountModal, setShowLogoutAccountModal] = useState(false);
  const [showRemoveAccountModal, setShowRemoveAccountModal] = useState(false);
  const { setChangeRegionModalOpenStatus } = useContext(HypercareAuthRegionModalContext);
  const currentRegion = getParsedAuthRegion();
  const [currentSelectedAcc, setCurrentSelectedAcc] = useState<SavedOrganization | null>(null);
  const [loginToAccountLoader, setLoginToAccountLoader] = useState(false);

  const { logoutOfAccount, removeAccount } = LogoutViewModel();
  const [suggestedOrganizations, setSuggestedOrganizations] = useState([]);

  const handleSwitchRegion = () => {
    setChangeRegionModalOpenStatus(true);
  };

  const handleOpenLogoutAllAccountsModal = () => {
    setShowLogoutAllAccountsModal(true);
  };

  const handleOpenRemoveAccountModal = (organization: SavedOrganization) => {
    let isUserSignedIn = isAccessTokenValid(organization);

    setCurrentSelectedAcc(organization);
    isUserSignedIn ? setShowLogoutAccountModal(true) : setShowRemoveAccountModal(true);
  };

  const handleRemoveAccount = () => {
    const removeAccountResponse = removeAccount(currentSelectedAcc);

    if (removeAccountResponse?.success && removeAccountResponse?.data?.savedOrganizations) {
      setSavedAccountsData(removeAccountResponse?.data.savedOrganizations);
      toast.info(
        `${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name}) has been removed from browser.`,
      );
    } else {
      toast.error(`Unable to remove account, please refresh to try again`);
    }

    setIsEditMode(false);
    setShowLogoutAccountModal(false);
    setShowRemoveAccountModal(false);
  };

  const handleLogoutOfAccount = () => {
    const logoutResponse = logoutOfAccount(currentSelectedAcc);

    if (logoutResponse?.success && logoutResponse?.data?.savedOrganizations) {
      setSavedAccountsData(logoutResponse?.data.savedOrganizations);
      toast.info(
        `Logged out of account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization?.name})`,
      );
    } else {
      toast.error(`Unable to logout of account, please refresh to try again`);
    }

    setIsEditMode(false);
    setShowLogoutAccountModal(false);
  };

  const handleRemoveAllAccountsOnDevice = () => {
    localStorageService.removeItem(ORGANIZATION_ACCOUNTS_DATA);
    localStorageService.removeItem(AUTH_INFO);
    setSavedAccountsData([]);
    window.location.assign(`/`);
    setIsEditMode(false);
    setShowLogoutAllAccountsModal(false);
    toast.info(`Successfully logged out from all accounts`);
  };

  // useEffect(() => {
  //   const fetchSuggestedOrganizations = async () => {
  //     const organizations = await retrieveSuggestedOrganizations();
  //
  //     if (organizations && organizations.length > 0) {
  //       setSuggestedOrganizations(organizations);
  //     }
  //   };
  //
  //   fetchSuggestedOrganizations();
  // }, []);

  const handleLoginToAccount = async (savedOrg: SavedOrganization) => {
    setLoginToAccountLoader(true);
    let isUserSignedIn = isAccessTokenValid(savedOrg);

    if (!isUserSignedIn) {
      const formatResponse = formatSavedOrgPayloadForTransfer(savedOrg, currentRegion);
      const base64EncodedResponse = decodeObjectToBase64(formatResponse);

      const encodedUriString = encodeURIComponent(
        `redirectUri=${getRedirectUriForAdmin()}&selectedAccount=${base64EncodedResponse}`,
      );

      window.open(`${getClientWebAppUrl()}?${encodedUriString}`, '_self');
    } else {
      // log in
      localStorage.removeItem(LAST_ACTIVE_TIME);
      await staLogin(savedOrg);
    }
    setLoginToAccountLoader(false);
  };

  return (
    <>
      <LoginContainer loading={loginToAccountLoader ? 'true' : undefined} width={'550px'}>
        {isEditMode ? (
          <AccountSelectionEditModeTitle title={'Manage accounts'} onClick={() => setIsEditMode(false)} />
        ) : (
          <AccountSelectionTitle handleSwitchRegion={handleSwitchRegion} currentAuthRegion={currentRegion} />
        )}
        <RowItemContainer>
          {savedAccountsData?.map((savedOrg) => (
            <SavedAccountOrganizationRowItems
              key={savedOrg.user.id}
              user={savedOrg.user}
              organization={savedOrg.organization}
              isSignedIn={isAccessTokenValid(savedOrg)}
              isEditMode={isEditMode}
              handleRowClick={() => handleLoginToAccount(savedOrg)}
              rightSideContent={
                isEditMode ? (
                  <RowItemsRightSide onClick={() => handleOpenRemoveAccountModal(savedOrg)}>
                    <CloseButtonV2 />
                  </RowItemsRightSide>
                ) : (
                  <></>
                )
              }
            />
          ))}
        </RowItemContainer>
        <SavedAccountActionButtons
          onIconClick={handleNextStep}
          icon={<AddButtonV2 />}
          description={
            <HCLabelOne color={theme.mainFontColor} lineHeight={'24px'}>
              {LOGIN_PAGE_ADD_OR_CREATE_ANOTHER_ACCOUNT}
            </HCLabelOne>
          }
        />
        {isEditMode ? (
          <SavedAccountActionButtons
            icon={<CloseButtonV2 />}
            onIconClick={handleOpenLogoutAllAccountsModal}
            description={
              <HCLabelOne color={theme.mainFontColor} lineHeight={'24px'}>
                {LOGIN_PAGE_REMOVE_ALL_ACCOUNT}
              </HCLabelOne>
            }
          />
        ) : (
          <SavedAccountActionButtons
            icon={<GearIcon />}
            onIconClick={() => setIsEditMode(true)}
            description={
              <HCLabelOne color={theme.mainFontColor} lineHeight={'24px'}>
                {LOGIN_PAGE_MANAGE_ACCOUNTS_ACCOUNT}
              </HCLabelOne>
            }
          />
        )}
        {showLogoutAllAccountsModal && (
          <LogoutAllAccountsModal
            isOpen={showLogoutAllAccountsModal}
            setIsOpen={setShowLogoutAllAccountsModal}
            handleOnClick={handleRemoveAllAccountsOnDevice}
          />
        )}
        {showLogoutAccountModal && (
          <AccountLogoutModal
            title={`Logout out of ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization.name})`}
            subTitle={`We recommend also removing your account from device upon logging out if you’re using a shared device.`}
            isOpen={showLogoutAccountModal}
            setIsOpen={setShowLogoutAccountModal}
            modalButtons={[
              {
                type: 'primary',
                buttonLabel: 'Logout & Remove',
                onClickHandler: handleRemoveAccount,
                id: 'cancel-btn',
              },
              {
                type: 'primary',
                buttonLabel: 'Logout',
                onClickHandler: handleLogoutOfAccount,
                id: 'remove-btn',
              },
            ]}
          />
        )}
        {showRemoveAccountModal && (
          <AccountLogoutModal
            title={`Remove account ${currentSelectedAcc?.user.firstname} ${currentSelectedAcc?.user.lastname} (${currentSelectedAcc?.organization.name}) from browser?`}
            subTitle={`You’ll need to enter your credentials again the next time you log into Hypercare on this device.`}
            isOpen={showRemoveAccountModal}
            setIsOpen={setShowRemoveAccountModal}
            modalButtons={[
              {
                type: 'secondary',
                buttonLabel: 'Cancel',
                onClickHandler: () => setShowRemoveAccountModal(false),
                id: 'cancel-btn',
              },
              {
                type: 'primary',
                buttonLabel: 'Remove',
                onClickHandler: handleRemoveAccount,
                id: 'remove-btn',
              },
            ]}
          />
        )}
      </LoginContainer>
      {/*{suggestedOrganizations.length > 0 && (*/}
      {/*  <SuggestedOrganizationsList*/}
      {/*    organizations={suggestedOrganizations}*/}
      {/*    handleSuggestedOrganizationClick={() => null}*/}
      {/*  />*/}
      {/*)}*/}
    </>
  );
};
